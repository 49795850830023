#player {
    background-color: var(--baby-pink);
    opacity: 0.9;
    position: fixed;
    min-width: 400px;
    height: 40px;
    padding: 16px;
    bottom: 0;
    right: 16px;
}
