input,
select {
    margin-right: 16px;
    margin-left: 8px;
}

.label {
    margin-right: 16px;
}

.filter input[type="number"] {
    width: 100px;
}

.filter {
    margin-bottom: 16px;
}

header {
    border: 1px solid lime;
    width: 100%;
    float: left;
    margin-bottom: 16px;
}

header h1 {
    float: left;
    margin: 0;
    border: 1px solid red;
}
