.key {
    font-weight: bold;
}

.key-0 {
    /* C */
    color: #d5282f;
}

.key-1 {
    color: #de4d23;
}

.key-2 {
    color: #d96320;
}

.key-3 {
    color: #e9c326;
}

.key-4 {
    /* E */
    color: #d1ee32;
}

.key-5 {
    color: #9dc92d;
}

.key-6 {
    /* F# */
    color: #4c9c2a;
}

.key-7 {
    color: #338e6f;
}

.key-8 {
    /* G */
    color: #4494a5;
}

.key-9 {
    color: #603784;
}

.key-10 {
    color: #91347d;
}

.key-11 {
    color: #d62552;
}
