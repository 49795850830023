:root {
    /* https://coolors.co/2f323a-77567a-c47ac0-fffffa-debac0 */
    --gunmetal: #2f323aff;
    --chinese-violet: #77567aff;
    --french-mauve: #c47ac0ff;
    --baby-powder: #fffffaff;
    --baby-pink: #debac0ff;
}

html,
body,
#root {
    height: 100%;
    margin: 0px;
}

.App {
    margin: 32px 32px;
}

.link {
    cursor: pointer;
}

button {
    cursor: pointer;
}

.hidden {
    display: none;
}

.home-menu {
    padding: 0.5em;
    text-align: center;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: var(--baby-powder);
}
.home-menu {
    background: var(--chinese-violet);
}

.main-content {
    margin-top: 32px;
}

.pure-menu-heading {
    text-transform: none;
    color: var(--baby-powder);
}

.pure-menu-selected > .pure-menu-link,
.pure-menu-selected > .pure-menu-link:visited {
    color: var(--baby-powder);
}

.pure-menu-link {
    color: var(--baby-powder);
}

.pure-menu-active > .pure-menu-link,
.pure-menu-link:hover,
.pure-menu-link:focus {
    background-color: var(--gunmetal);
}
