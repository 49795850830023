#loginPage {
    background-color: var(--gunmetal);
    min-height: 100%;
    padding: 20px;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

#loginPage h1 {
    color: var(--baby-pink);
    font-size: 130px;
}

#loginPage img {
    width: 280px;
}

#loginPage span {
    color: var(--baby-powder);
    font-weight: bold;
    font-size: 24px;
}
