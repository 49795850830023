#tracks {
    font-size: 15px;
    margin-bottom: 112px;
}

.highlight {
    background-color: #debac05b;
    /* font-weight: bold; */
}

.col-releasedate {
    min-width: 100px;
}

#tracks .link {
    cursor: pointer;
    text-decoration: none;
}

#tracks .link:hover {
    text-decoration: underline;
}
